<template>
  <div class="container" :style="{ '--themeColor': $store.state.themeInfo.themeColor || '#1C3876' }">
    <van-sticky>
      <head-bar type="primary" title="新区风采" @onClickLeft="onClickLeft" />
    </van-sticky>
    <div class="video-wraper">
      <video :src="video.linkUrl" autoplay="autoplay" controls class="video"></video>
      <div class="video-info">
        <div class="name">{{ video.name }}</div>
        <div class="view-count">
          <!-- <van-icon name="eye-o" /> -->
          <!-- {{ video.createTime.substr(0, 10) }} -->
          <!-- 2020年4月7日 -->
        </div>
        <!-- <span class="click-num">
          <img :src="require('@/assets/images/ylfh/icon_eye.png')" alt="">
          <span>{{video.clickNum}}</span>
        </span> -->
      </div>
      <van-divider></van-divider>
      <div class="more-video">
        <div class="title">更多精彩视频</div>
      </div>
      <div class="swiper-con">
        <div class="swiper-main">
          <div class="video-swiper-container daka-wraper">
            <div class="swiper-wrapper">
              <div v-for="(item, index) in videoList" :key="index" :data-index="index" class="swiper-slide daka-item">
                <div class="img-box">
                  <img :src="item.iconUrl+'?imageView2/2/w/375'" alt="">
                  <van-icon class="play-icon" name="play-circle" />
                </div>
                <!-- <div class="daka-title">item.title</div> -->
                <div class="daka-desc">《{{ item.name }}》</div>
              </div>
            </div>
            <div class="video-swiper-pagination"></div>
          </div>
        </div>
      </div>
    </div>
    <float-menu />
  </div>
</template>

<script>
import headBar from '@/components/headBar/headBar'
import Swiper from 'swiper/js/swiper.js'
import floatMenu from '@/components/floatMenu/index'
import 'swiper/css/swiper.css'
export default {
  name: 'micevideo',
  components: { headBar, floatMenu },
  data() {
    return {
      viewCount: 6320,
      swiper: null,
      video: {
        linkUrl: ''
      },
      videoList: []
    }
  },
  mounted() {
    // if (this.$store.state.videoInfo.videoList.length === 0) {
      this.getVideoList()
    // } else {
    //   this.$nextTick(() => {
    //     this.initSwiper()
    //   })
    // }
    this.$utils.setStorage('location', window.location.href)
  },
  methods: {
    onClickLeft() {
      this.$router.push('/index/' + this.$store.state.miceInfo.miceLink || this.$route.params.miceLink)
    },
    async getVideoList() {
      let result = await this.$http.get(this.$baseURL.index.getVideoList() + '?miceLink=' + this.$store.state.miceInfo.miceLink, 1)
      if (result.code === 200) {
        this.videoList = result.data
        if (this.$route.params.videoId) {
          this.video = this.videoList.find(item => {
            return item.id == this.$route.params.videoId
          })
        }
       
        this.$nextTick(() => {
          this.initSwiper()
        })
      } else {
        // Dialog.alert({
        //   title: '提示',
        //   message: result.message,
        // }).then(() => {
        //   // on close
        // })
      }
    },
    initSwiper() {
      const vm = this
      this.swiper = new Swiper('.video-swiper-container', {
        slidesPerView: 2,
        spaceBetween: -10,
        observer:true,
        autoplay: {
          delay: 5000,
          disableOnInteraction: false
        },
        pagination: {
          el: '.video-swiper-pagination'
        },
        loop: true,
        speed: 600,
        on: {
          click: function () {
            vm.setCurrent(vm.videoList[this.clickedSlide.dataset.index])
          }
        }
      })
    },
    setCurrent(item) {
      this.video = item
      this.addClickNum(item.id)
    },
    // 新增点击量
    addClickNum(id) {
      this.$http.post(this.$baseURL.index.clickNum(), {
        id,
        tableType: 3
      }, 1).then((res) => {
        console.log(res)
      })
    }
  }
}
</script>

<style lang="less" scoped>
@themeColor: var(--themeColor);
.container{
  height: 100vh;
  background: rgb(242, 248, 252);
  .swiper-con{
    padding: 0 15px;
  }
}
.more-video{
  margin: 10px 0;
  padding: 0 15px;
  font-size: 13px;
}
.video-wraper{
  height: 220px;
  width: 100%;
  .video{
    height: 100%;
    width: 100%;
    object-fit: fill;
  }
  .video-info{
    padding: 0 15px;
    font-size: 13px;
    color: #3E3F53;
    .name{
      font-size: 15px;
      font-weight: bold;
      margin-bottom: 5px;
    }
    .view-count{
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 12px;
      width: 150px;
      color: #999;
    }
  }
  .swiper-main{
    position: relative;
    width: 100%;
    overflow: hidden;
    background: #fff;
    box-shadow: 1px 0 3px 0 rgba(0 ,0 ,0, .05);
    border-radius: 10px;
    padding: 3px 0 25px;
    .video-swiper-pagination{
      display: flex;
      justify-content: center;
      height: 30px;
      // width: 100%;
      align-items: center;
      padding: 10px 0;
      position: absolute;
      width: 100%;
      bottom: 0;
      left: 0;
      /deep/.swiper-pagination-bullet{
        height: 6PX;
        width: 6PX;
        transition: all ease .3s;
        background: rgba(0 ,0 ,0, .23);
        margin: 0 5px;
      }
      /deep/.swiper-pagination-bullet-active{
        width: 20PX;
        border-radius: 3PX;
        background: @themeColor;
      }
    }
    .daka-item{
      display: flex;
      flex-direction: column;
      // align-items: center;
      justify-content: center;
      overflow: hidden;
      transform: scale(.88);
      font-size: 12px;
      color: #3E3F53;
      .img-box{
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 35px;
        height: 100px;
        img{
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 8px;
        }
        .play-icon{
          position: absolute;
          color: #fff;
        }
      }
      .daka-desc{
        width: 100%;
        margin-top: 5px;
      }
    }
  }
}

.click-num {
  font-size: 11px;
  color: #696969;
  img {
    width: 13px;
    margin-right: 3px;
  }
}
</style>